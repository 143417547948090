// datesheet component
@media (max-width: @screen-xs-max) {
  .datesheet {
    & > span {
      font-size: @font-size-h4;
      display: inline-block;
    }
    .day {
      padding: 0 0.25em;
    }
  }
}  
@media (min-width: @screen-sm-min) {
  .datesheet {
    & > span {
      display: block;
      text-align: center;
      font-weight: bold;
      // color: $cs-text-light;
    }
    & > .month {
      .equal_font_size_line_height(@font-size-base );
      font-weight: 700;
    }
    & > .day {
      .equal_font_size_line_height(1.25 * @font-size-base);
      padding: 0.3em 0;
    }
    & > .year {
      .equal_font_size_line_height(0.8 * @font-size-base);
    }

    display: inline-block;
    // .single-box-shadow(nth(@black-tints,3), 3px, 3px, 3px, false, false);

    padding: 0.2em 0.3em;
    margin-right: 0.5em;

    background-color: white;
    box-shadow: 1px 1px 2px 2px #ddd;
  }
}

.separator {
  padding: 0 0.5em;
}
@media (min-width: @screen-sm-min) {
  .separator {
    display: none;
  }
}