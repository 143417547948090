

// helpers
.equal_font_size_line_height(@size) {
  font-size: (@size);
  line-height: (@size);
}
.no-bullets {
  padding-left: 0;
  list-style-type: none;
}
// 100% width image
.img100pro img {
  width: 100%;
  height: auto;
}
.inline {
  display: inline;
}
.white-box {
  background-color: white;
  padding: 10px 15px;
  border-radius: 6px;
}
// margin bottom
.mb {
  margin-bottom: 4rem;
}

.shadow {
  box-shadow: #bbb 0 0 10px;
}
.shadow-darker {
  box-shadow: #999 0 0 10px;
}



// event detail display
.event-metadata {
  .involved {
    &:extend(h4);
  }
  .date, .place {
    &:extend(h3);
  }
}


// in ctools modal form allow scrolling
.modal-content {
  overflow: scroll;
}

// in entity reference fields add some space to add a "create content" link
.field-type-entityreference {
  label {
    display: block;
  }
  .input-group {
    width: 66%;
    float: left;
  }
  span.form-required {
    display: none;
  }
  .form-group {
    margin: 0;
  }
  td {
    padding: 4px 8px !important;
  }
}

// fix chosen display issues (interfering with bootstrap)
.chosen-container {
  background-color: transparent;
  border: none;
  padding: 0;
  height: auto;
}

// limit date popup field width to a yyyy-mm-dd width
.form-type-date-popup input {
  width: 8em;
  z-index: 20;
}
// for datepicker to correctly calculate the z-Index, the field has to be have position: relative or absolute
.hasDatepicker {
  position: relative;
}


// apply some themeing to ds based node edit form
.ds-2col-stacked {
  .group-right {
    padding-left: 6%;
  }
}

body {
  padding-top: 70px; //
}
