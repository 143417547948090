
// a Drupal block with background color and padding
.panel-block {
  .inner {
    background-color: white;
    padding: @panel-body-padding;
  }
  h2 {
    margin-top: 0;
  }
  margin-bottom: 36px;

  // for blocks with blue background
  &.panel-block-dark {
    h2 {
      font-weight: bold;
    }

    .inner {
      background-color: lighten(#07455E, 10%);
      color: #f8f8f8;
    }
  }
}

// position the floating nav
.floating-nav {
  // &.affix-top {
  //   
  // }
  &.affix {
    position: fixed;
    display: block;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    a {
      display: block;
      font-size: @font-size-h2;
      padding: @padding-large-vertical @padding-large-horizontal;
      background-color: #ccc;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
}


// scale font-sizes in sidebar down
.region-sidebar-second {
  h2.block-title {
    font-size: @font-size-h4;
  }
  h3.block-title {
    font-size: @font-size-h5;
  }
  h4.block-title {
    font-size: @font-size-h6;
  }
}

// @see http://stackoverflow.com/questions/10099422/flushing-footer-to-bottom-of-the-page-twitter-bootstrap
html, body {
  height: 100%;
}

#wrap {
  min-height: 100%;
}

.main-container {
  overflow:auto;
  padding-bottom:92px; /* this needs to be bigger than footer height*/
}

.footer {
  position: relative;
  margin-top: -92px; /* negative value of footer height */
  height: 92px;
  clear:both;
  padding-top:20px;
}
