
// linked image thumbnails, that show a hover text
a.img_hover {
  position: relative;
  display: block;
  margin-bottom: 1px;

  // display of the text
  color: #fff;

  // hover effects 
  &:hover > .img_hover_text {
    opacity: 0.0;
  }

  &:hover > .img_hover_bg {
    opacity: 0.0;
  }

  // positioning element fill the full available space 
  & > .img_fill_box {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  // properties of the grey background
  & > .img_hover_bg {
    // background-color: #555;
    background-color: #888;
    opacity: 0.85;
    transition: all 0.3s;
  }
  // properties of the text 
  & > .img_hover_text {
    padding: 1em;
    transition: all 0.3s;
  }
}


