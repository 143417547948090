

.view-recent-activity {

  .col-img {
    width: 20%;
    float: left;
    img {
      width: 100%;
    }
  }
  .col-message {
    padding-left: @padding-base-horizontal;
    width: 79.9%;
    float: right;
  }

  // separators
  .msg-row {
    overflow: hidden;

    border-bottom: 1px solid #ddd;
    padding-bottom: 1em;
    margin-bottom: 1em;

    &:last-of-type {
      border: none;
      padding: 0;
    }
  }
}