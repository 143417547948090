
/* TODO - bootstrap has already a panel
 * component, but I need something for the front page
 */
// .panel {
//   &.dark {
//     @include panel(lighten(#07455E, 10%));
//     a {
//       color: $callout-panel-link-color;
//     }
//     color: #ddd;
//   }
//   &.light {
//     @include panel(#fff);
//     a {
//       color: $callout-panel-link-color;
//     }
//   }

// }

// .page-footer {
//   background-color: darken($primary-color, 10%);
//   padding: $panel-padding;
//   margin-top: $panel-padding;
//   a {
//     //TODO @include link-colors(#fff,#fff,#fff,#fff,#fff)
//   }

// }


// polaroid images
.polaroid-container {
  position: relative;
}

.polaroid {
  padding: 1em 0.75em 2em;
  border: 1px solid #aaa;
  background-color: white;
  position: absolute;
  width: 62%;
}

.deg5 {
  transform: rotate(5deg);
  top: -1em;
}

.deg10 {
  transform: rotate(10deg);
  top: -2em;
  left: 11em;
}

// change to 2col display for wide screen
#block-views-upcoming-events-block-1 .view-content {
  @media (min-width: @screen-md-min) {
    column-count: 2;
  }
}
