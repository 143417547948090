
.new {
  color: @brand-warning;
  font-weight: bold;
}

#comments {
  max-width: 700px;
}

.indented {
  margin-left: 30px;
}

.comment {
  padding: @padding-base-vertical @padding-base-horizontal;
  margin: 0 -@padding-base-horizontal;
  
  h3 {
    margin-top: 0px;
    a {
      color: #333;
    }
  }
  .links {
    visibility: hidden;
    margin: 0;
  }
  &:hover {
    background: white;
    .links {
      visibility: visible;
    }
  }
}