
// visualize changebility of current user's picture
.user-picture-mine img {
  margin: 0 -5px;
  border: 5px solid transparent;
  transition: border-color 0.3s;
  
  &:hover {
    border-color: @link-color;
  }
}

