
// add shadows for block on front
// .front .inner,
.shadow .inner { // for a single block
  box-shadow: #bbb 0 0 10px;
}
.shadow-darker {
  box-shadow: #999 0 0 10px;
}

// @see also custom.less

.w100pro {
  width: 100%;
}
.w50pro {
  width: 49.9%;
}

.w25pro {
  width: 24.9%;
}

.w75pro {
  width: 74.9%;
}

.baspadtop {
  padding-top: @padding-base-vertical;
}
.baspadbot {
  padding-bottom: @padding-base-vertical;
}
.baspadri {
  padding-right: @padding-base-vertical;
}
.baspadle {
  padding-left: @padding-base-vertical;
}


.lgpadtop {
  padding-top: @padding-large-vertical;
}
.lgpadbot {
  padding-bottom: @padding-large-vertical;
}
.lgpadri {
  padding-right: @padding-large-vertical;
}
.lgpadle {
  padding-left: @padding-large-vertical;
}

.no-padding {
  padding: 0;
}
.no-margin {
  margin: 0;
}


// compact views and their pagers by removing some margins
.compact {
  table {
    margin-bottom: 0;
  }
  .pager {
    margin: 0;
  }
}

.marker {
  color: @brand-warning;
}

.img-drop-shadow img {
  box-shadow: 0px 0px 10px #888888
}