
// facetapi-links
ul.facetapi-facetapi-links {
	padding-left: 0;
}

// make search form bigger on focus
// @see http://stackoverflow.com/questions/19832878/bootstrap-and-search-input-width-change-on-focus
@media @tablet { 
  #block-search-form .expand-on-focus .form-text {
    width: 100px;
    transition: width 0.5s ease-in-out;
    &:focus{
      width: 200px;
    }
   }
}

// display of search results
.search-result {
  padding: @padding-base-vertical @padding-base-horizontal;
  margin: 0 -@padding-base-horizontal;
  
  h3 {
    font-weight: bold;
  }

  transition: background-color 0.25s;
  &:hover {
    background-color: white;
  }
  .search-info {
    color: @gray-light;
  }
}