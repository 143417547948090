// event-list exposed filter form
.form-item input.bef-datepicker {
  width: 10em;
}


// event-list component
.event-list {
  .event-list__title {
    &:extend(h3);
    margin-top: 0;
  }

  @media (max-width: @screen-xs-max) {
    .event-list__img img {
      float: right;
      padding-right: 1em;
      width: 25%;
    }
  }
  @media (min-width: @screen-sm-min) {
    .event-list__img img {
      display: block;
      width: 90%;
    }
  }


  .row {
    padding: @padding-large-vertical @padding-large-horizontal;
    transition: background-color 0.4s;
    &:hover  {
      background-color: white; 
      transition: background-color 0.1s;
    }
  }
}

// calendar node form : display of chosen fields
.group-right .form-control {
  width: 100%;
}

// fix exposed filter display
#edit-field-date-value-value-wrapper .control-label {
  display: none;
}